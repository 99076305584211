<template>
  <el-dialog title="操作提示" class="k-setRider-dialog" :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="visible"
    width="440px">
    <span><i class="sx-icon-tishi" slot="reference"></i> {{title}}</span>
    <div style="text-align:right;">
      <el-button @click="visible = false" size="small" :loading="loading" :disabled="loading">取 消</el-button>
      <el-button type="primary" @click="submitInfo" :loading="loading" :disabled="loading" size="small">确 定</el-button>
    </div>
    <span slot="footer" class="dialog-footer"></span>
  </el-dialog>
</template>
<script>
import { debounce } from '@/utils/index';

export default {
  props: {
    title: String,
    handleDialog: Boolean,
    // loading: Boolean,
  },
  model: {
    prop: 'handleDialog',
    event: 'closed',
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    visible: {
      get() {
        return this.handleDialog;
      },
      set(val) {
        this.$emit('closed', val);
      },
    },
  },
  methods: {
    // eslint-disable-next-line func-names
    submitInfo: debounce(function () {
      this.loading = true;
      this.$emit('submitInfo');
    }, 2000, {
      leading: true,
      trailing: false,
    }),
  },
};
</script>
<style scoped lang="scss">
.k-setRider-dialog ::v-deep {
  .el-dialog__header {
    padding: 10px 12px;
  }
  .el-dialog__body {
    padding: 20px 12px 10px;
  }
  .sx-icon-tishi {
    color: rgb(212, 165, 8);
  }
}
</style>
