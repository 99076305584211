import api from './api';

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 渠道列表
// 渠道列表
export const $sg_channelList = (params) => api.get('/sssk/api/platform/biz/channel/queryList', params);
// 新增渠道
export const $sp_addChannel = (params) => api.post('/sssk/api/platform/biz/channel/saveChannelAccess', params);
// 编辑渠道
export const $sp_updateChannel = (params) => api.post('/sssk/api/platform/biz/channel/updateChannelAccess', params);
// 获取渠道详情
export const $sg_selectByChannelId = (params) => api.get('/sssk/api/platform/biz/channel/selectByChannelId', params);
// 渠道禁用恢复
export const $sg_disableByChannelId = (params) => api.get('/sssk/api/platform/biz/channel/disableByChannelId', params);
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 订单列表
// 订单列表
export const $sg_getChannelOrderList = (params) => api.get('/sssk/api/platform/biz/channelOrderInfo/getChannelOrderList', params);
// 订单统计
export const $sg_getChannelOrderStatistics = (params) => api.get('/sssk/api/platform/biz/channelOrderInfo/getChannelOrderStatistics', params);
// 订单详情 
export const $sg_getOrderInfo = (params) => api.get('/sssk/api/platform/biz/channelOrderInfo/getOrderInfo', params);
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 待审核订单
// 待审核列表 
export const $sg_queryOrderList = (params) => api.get('/sssk/api/platform/biz/channel/refund/queryOrderList', params);
// 审核接口
export const $sp_audit = (params) => api.post('/sssk/api/platform/biz/channel/refund/audit', params);
