<template>
  <div class="thirdParty-page wh">
    <PageHeader prompt="<p>已退款订单不计入统计数据</p>" :statisticalData="statisticalData" />
    <el-form :inline="true" :model="searchForm" class="search-params" style="width: 100%">
      <el-form-item label="创建时间">
        <el-date-picker v-model="times" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期" @change="chooseTime">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="联联订单号">
        <el-input v-model="searchForm.llOrderId" maxlength="50" placeholder="输入联联订单号" clearable></el-input>
      </el-form-item>
      <el-form-item label="渠道订单号">
        <el-input v-model="searchForm.channelOrderId" maxlength="50" placeholder="输入渠道订单号" clearable></el-input>
      </el-form-item>
      <el-form-item label="客户电话">
        <el-input v-model="searchForm.customerTel" placeholder="请输入客户电话" clearable></el-input>
      </el-form-item>
      <el-form-item label="核销状态">
        <el-select v-model="searchForm.status" placeholder="请选择" clearable>
          <el-option v-for="(item,index) in writeOffTypes" :key="index" :label="item" :value="+index">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="退款状态">
        <el-select v-model="searchForm.refundStatus" placeholder="请选择" clearable>
          <el-option v-for="(item,index) in refundTypes" :key="index" :label="item" :value="+index">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="归属渠道">
        <el-select v-model="searchForm.belongChannel" filterable remote :remote-method="getChannelListsData"
          @focus="()=>getChannelListsData()" placeholder="请选择" clearable>
          <el-option v-for="item in channelOptionsData" :key="item.id" :label="item.channelName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="search-button">
        <el-button type="primary" @click="getDataList(1)">搜索</el-button>
        <el-button type="info" @click="resetGetData">重置</el-button>
        <el-button type="primary" @click="dialogTableVisible = true">导出</el-button>
      </el-form-item>
    </el-form>
    <div class="thirdParty-page-content-table">
      <el-table :data="tableData" :cell-style="cellStyle" :height="tableHeight" v-loading="tableLoading" element-loading-text="数据加载中..."
        class="thirdParty-table-css" style="width: 100%;" border>
        <el-table-column prop="belongChannel" label="归属渠道" min-width="180" />
        <el-table-column prop="channelOrderId" label="渠道订单号" width="160" />
        <el-table-column prop="llOrderId" label="联联订单号" width="160" />
        <el-table-column prop="shopName" label="店铺名称" min-width="180" />
        <el-table-column prop="customerTel" label="客户电话" width="110">
          <template slot-scope="scope">
            {{phoneFilter(scope.row.customerTel)}}
          </template>
        </el-table-column>
        <el-table-column prop="creatTime" label="订单创建时间" width="160" />
        <el-table-column label="核销状态" width="80">
          <template slot-scope="scope">
            {{writeOffTypes[scope.row.status] || '-'}}
          </template>
        </el-table-column>
        <el-table-column label="退款状态" width="80">
          <template slot-scope="scope">
            {{refundTypes[scope.row.refundStatus] || '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="costPrice" label="成本价" width="90" />
        <el-table-column prop="channelPrice" label="渠道价" width="90" />
        <el-table-column label="平台收益" width="90">
          <template slot-scope="scope">
            {{scope.row.refundStatus===2?'-':scope.row.platformFinance || '-'}}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="80">
          <template slot-scope="scope">
            <el-button type="text" @click="orderDetail(scope.row.id)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="thirdParty-el-pagination" background :page-count="totalPage" :current-page.sync="searchForm.pageCurrent"
      @current-change="getDataList">
    </el-pagination>
    <ExportDialog title="确认导出订单数据" v-model="dialogTableVisible" @export="exportDataLists">
      <template v-slot:tableKey>
        归属渠道、渠道订单号、联联订单号、店铺名称、客户电话、订单创建时间、核销状态、订单实付金额、成本价、渠道商收益、平台收益
      </template>
    </ExportDialog>
  </div>
</template>

<script>
import exportFileSync from '@/utils/file/exportFileSync';
import { $sg_getChannelOrderList, $sg_getChannelOrderStatistics } from '@/api/channel';
import thirdMixins from '../thirdMixins';
import PageHeader from '../components/pageHeader.vue';
import ExportDialog from '../components/exportDialog.vue';
import { WRITE_OFF_TYPES, REFUND_TYPES } from '../thirdParty';

export default {
  name: 'OrderLists',
  mixins: [thirdMixins],
  components: {
    ExportDialog,
    PageHeader,
  },
  data() {
    return {
      dialogTableVisible: false,
      times: [],
      statisticalData: {
        totalOrderCount: {
          index: 0,
          label: '总订单量/笔',
          value: 0,
        },
        // totalPayPrice: {
        //   index: 1,
        //   label: '总实付金额/元',
        //   value: 0,
        // },
        totalPlatformFinance: {
          index: 2,
          label: '平台总收益/元',
          value: 0,
        },
      },
      searchForm: {
        belongChannel: '', // 归属渠道
        channelOrderId: '', // 渠道订单号
        customerTel: '', // 客户电话
        endTime: '', // 结束时间
        llOrderId: '', // 联联订单号
        refundStatus: '', // 退款状态 1 退款中 2 退款成功 3 退款失败
        startTime: '', // 开始时间
        status: '', // 核销状态 1 待核销 2 已核销 3 已失效
        pageCurrent: 1,
        pageSize: 15,
      },
      channelOptionsData: [], // 渠道列表
    };
  },
  created() {
    this.writeOffTypes = WRITE_OFF_TYPES;
    this.refundTypes = REFUND_TYPES;
    this.getDataList();
  },
  methods: {
    getDataList(page = null) {
      if (page) {
        this.searchForm.pageCurrent = page;
      }
      this.tableLoading = true;
      $sg_getChannelOrderStatistics({ ...this.searchForm, pageCurrent: undefined, pageSize: undefined }).then((d) => {
        this.statisticalData.totalPlatformFinance.value = d?.totalPlatformFinance || 0;
      });
      $sg_getChannelOrderList({ ...this.searchForm }).then((d) => {
        this.statisticalData.totalOrderCount.value = d?.total || 0;
        this.setTableData(d);
      }).finally(() => {
        this.tableLoading = false;
      });
    },
    // 时间选择
    chooseTime(val) {
      this.searchForm.startTime = val?.[0] ? `${val[0]} 00:00:00` : '';
      this.searchForm.endTime = val?.[1] ? `${val[1]} 23:59:59` : '';
    },
    exportDataLists() {
      exportFileSync('/sssk/api/platform/biz/channelOrderInfo/export',
        {
          ...this.searchForm,
          pageCurrent: undefined,
          pageSize: undefined,
        },
        'get',
        '订单列表.xlsx');
      this.dialogTableVisible = false;
    },
    orderDetail(id) {
      this.$router.push(`/main/thirdPartyChannel/orderDetial/${id}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../thirdParty.scss";
</style>

