import { debounce } from '@/utils/index';
import { $sg_channelList } from '@/api/channel';

export default {
  data() {
    return {
      tableHeight: '100%', // 表格高度
      cellStyle: { padding: '4px 0' }, // 表格行高
      tableData: [], // 表格数据
      // pageCurrent: 1, // 当前页
      // pageSize: 15,
      totalPage: 0, // 总页数
      tableLoading: false, // 表格加载状态
    };
  },
  mounted() {
    this.setTableHeight();
    window.addEventListener('resize', this.setTableHeight);
  },
  methods: {
    setTableData(d) {
      const { records, pages } = d;
      this.tableData = records || [];
      this.totalPage = pages || 1;
    },
    // 获取渠道列表
    getChannelListsData(channelName = '') {
      console.log(channelName);
      const params = {
        channelName,
        channelContact: '',
        channelPhone: '',
        id: '',
        pageCurrent: 1,
        pageSize: 1000,
      };
      $sg_channelList({ ...params }).then((d) => {
        this.channelOptionsData = d.records || [];
      });
    },
    // 重置数据
    resetGetData() {
      if (this.times?.length) {
        this.times = [];
      }
      this.searchForm = this.$options.data().searchForm;
      this.getDataList();
    },
    // eslint-disable-next-line func-names
    setTableHeight: debounce(function () {
      const mainBox = document.getElementsByClassName('thirdParty-page-content-table')[0];
      if (mainBox) {
        this.tableHeight = mainBox.clientHeight;
      }
    }, 300, {
      leading: false,
      trailing: true,
    }),
    phoneFilter(val) {
      if (!val) return '-';
      const reg = /^(.{3}).*(.{4})$/;
      return val.replace(reg, '$1****$2');
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setTableHeight);
  },
};
