<template>
  <div class="thirdParty-page wh">
    <el-form :inline="true" :model="searchForm" class="search-params" style="width: 100%">
      <el-form-item label="渠道ID">
        <el-input v-model="searchForm.id" type="number" onInput="if(value.length>7)value=value.slice(0,7)" placeholder="输入渠道商ID" clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="渠道名称">
        <el-input v-model="searchForm.channelName" maxlength="15" placeholder="输入渠道商名称" clearable></el-input>
      </el-form-item>
      <el-form-item label="法人/联系人">
        <el-input v-model="searchForm.channelContact" maxlength="15" placeholder="请输入法人/联系人姓名" clearable></el-input>
      </el-form-item>
      <el-form-item label="联系人手机号">
        <el-input v-model="searchForm.channelPhone" type="number" onInput="if(value.length>11)value=value.slice(0,11)"
          placeholder="请输入联系人手机号" clearable>
        </el-input>
      </el-form-item>
      <el-form-item class="search-button">
        <el-button type="primary" @click="getDataList(1)">搜索</el-button>
        <el-button type="info" @click="resetGetData">重置</el-button>
        <el-button type="primary" @click="dialogTableVisible = true">导出</el-button>
        <el-button type="primary" icon="el-icon-plus" @click="addChannerl">新增渠道商</el-button>
      </el-form-item>
    </el-form>
    <div class="thirdParty-page-content-table">
      <el-table :data="tableData" :cell-style="cellStyle" :height="tableHeight" v-loading="tableLoading" element-loading-text="数据加载中..."
        class="thirdParty-table-css" style="width: 100%;" border>
        <el-table-column prop="createTime" label="添加时间" width="160" />
        <el-table-column prop="id" label="渠道ID" width="180" />
        <el-table-column prop="channelName" label="渠道名称" />
        <el-table-column prop="channelType" label="渠道分类" width="80">
          <template slot-scope="scope">
            {{channelTypes[scope.row.channelType] || '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="idPerson" label="法人" width="150" />
        <el-table-column prop="channelContact" label="联系人" width="150" />
        <el-table-column prop="channelPhone" label="联系人手机号" width="110">
          <template slot-scope="scope">
            {{phoneFilter(scope.row.channelPhone)}}
          </template>
        </el-table-column>
        <el-table-column prop="channelCity" label="所在城市" />
        <el-table-column prop="rate" label="服务费率" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.rate!=null">{{scope.row.rate}}% </span>
            <span v-else>'-'</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="状态" width="80">
          <template slot-scope="scope">
            {{channelStatus[scope.row.state] || '-'}}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="110">
          <template slot-scope="scope">
            <el-button type="text" @click="editChannel(scope.row)">编辑</el-button>
            <el-button type="text" @click="disabledData(scope.row)" v-if="scope.row.state">{{scope.row.state===1?'禁用':'启用'}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="thirdParty-el-pagination" background :page-count="totalPage" :current-page.sync="searchForm.pageCurrent"
      @current-change="getDataList">
    </el-pagination>
    <ExportDialog title="确认导出渠道数据" v-model="dialogTableVisible" @export="exportDataLists">
      <template v-slot:tableKey>
        渠道ID、渠道名称、渠道分类、法人、法人身份证、统一社会信用代码、联系人、联系人手机号、所在城市、服务费率
      </template>
    </ExportDialog>
    <HandleDataDialog ref="handleData" v-model="handleDialog" :title="`${channel&&channel.state===1?'禁用后该渠道无法正常使用,确认禁用？':'确认启用该渠道？'}`"
      @submitInfo="submitInfo" />
    <AddOreditChannerl v-model="addOreditChannerlDialog" :channelId="channel&&channel.id" v-if="addOreditChannerlDialog"
      @submitSuccess="getDataList" />
  </div>
</template>

<script>
import exportFileSync from '@/utils/file/exportFileSync';
import { $sg_channelList, $sg_disableByChannelId } from '@/api/channel';
import { CHANNEL_TYPES, CHANNEL_STATUS } from '../thirdParty';
import ExportDialog from '../components/exportDialog.vue';
import AddOreditChannerl from '../components/addOreditChannerl.vue';
import HandleDataDialog from '../components/handleDataDialog.vue';
import thirdMixins from '../thirdMixins';

export default {
  name: 'ChannelLists',
  mixins: [thirdMixins],
  components: {
    ExportDialog,
    AddOreditChannerl,
    HandleDataDialog,
  },
  data() {
    return {
      dialogTableVisible: false,
      addOreditChannerlDialog: false,
      handleDialog: false,
      searchForm: {
        channelContact: '',
        channelName: '',
        channelPhone: '',
        id: '',
        pageCurrent: 1,
        pageSize: 20,
      },
      channel: null, // 当前的渠道id
    };
  },
  created() {
    this.channelTypes = CHANNEL_TYPES;
    this.channelStatus = CHANNEL_STATUS;
    this.getDataList();
  },
  methods: {
    getDataList(page = null) {
      if (page) {
        this.searchForm.pageCurrent = page;
      }
      this.tableLoading = true;
      $sg_channelList({ ...this.searchForm }).then((d) => {
        this.setTableData(d);
      }).finally(() => {
        this.tableLoading = false;
      });
    },
    // 新增渠道
    addChannerl() {
      this.channel = null;
      this.addOreditChannerlDialog = true;
    },
    // 导出
    exportDataLists() {
      exportFileSync('/sssk/api/platform/biz/channel/export',
        {
          ...this.searchForm,
          pageCurrent: undefined,
          pageSize: undefined,
        },
        'get',
        '渠道列表.xlsx');
      this.dialogTableVisible = false;
    },
    // 编辑
    editChannel(row) {
      this.channel = row;
      this.addOreditChannerlDialog = true;
    },
    // 打开禁用提示框
    disabledData(row) {
      this.channel = row;
      this.handleDialog = true;
    },
    // 禁用或启用
    submitInfo() {
      const { id, state } = this.channel;
      $sg_disableByChannelId({ channelId: id, state: state === 1 ? 2 : 1 }).then(() => {
        this.getDataList();
        this.$message({
          message: '操作成功',
          type: 'success',
        });
        this.handleDialog = false;
      }).finally(() => {
        this.$refs.handleData.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../thirdParty.scss";
</style>
