export const reg2 = /^(\d{1,2}(\.[0-9]{0,2})?)$/; // 不超过2位 保留两位小数
export const getStringLen = (str) => {
  let total = 0;
  if (!str) {
    return total;
  }
  for (let index = 0; index < str.length; index += 1) {
    // eslint-disable-next-line no-control-regex
    if (/^[\u0000-\u00ff]$/.test(str[index])) {
      total += 1;
    } else {
      total += 2;
    }
  }
  return total;
};
// 获取指定时间的时间戳
export const getTime = (time) => {
  if (!time) return 0;
  return new Date(time).getTime();
};
export const FAILURE_TYPES = { // 是否失效
  1: '是',
  2: '否',
};
export const CHANNEL_STATUS = { // 渠道状态
  1: '正常',
  2: '禁用',
};
export const CHANNEL_TYPES = { // 渠道分类
  1: '企业',
  2: '个人',
};
export const AUDIT_TYPES = { // 审核状态
  1: '待审核',
  2: '审核成功',
  3: '驳回',
};
export const WRITE_OFF_TYPES = { // 核销状态
  1: '待核销',
  2: '已核销',
  3: '已失效',
};
export const REFUND_TYPES = { // 退款状态
  1: '退款中',
  2: '退款成功',
  3: '退款失败',
};
