<!--订单详情-->
<template>
  <div class="k-public-pagecss takeout-order-detail" v-loading="formLoading" element-loading-text="数据加载中...">
    <el-page-header class="page-header-custorm" @back="$router.go(-1)">
      <template #content>
        <p class="marketing-type">联联团购订单/订单详情<span class="order-status" v-if="orderStatus">({{orderStatus}})</span></p>
      </template>
    </el-page-header>
    <DetailInfoItem title="用户信息">
      <template v-slot:content>
        <ul class="order-info-lists">
          <li>用户昵称：{{orderDetailInfo.customerNickName || '-'}}</li>
          <li>用户手机号：{{orderDetailInfo.customerTel | phoneFilter}}</li>
          <li>归属渠道：{{orderDetailInfo.belongChannel || '-'}}</li>
        </ul>
      </template>
    </DetailInfoItem>
    <DetailInfoItem title="店铺信息">
      <template v-slot:content>
        <ul class="order-info-lists">
          <li>店铺名称：{{shopInfo.shopName || '-'}}</li>
          <li>联系电话：{{shopInfo.shopTel | phoneFilter}}</li>
          <li>店铺地址：{{shopInfo.shopAddress || '-'}}</li>
        </ul>
      </template>
    </DetailInfoItem>
    <DetailInfoItem title="退款信息" v-if="orderDetailInfo.refundStatus===2">
      <template v-slot:content>
        <ul class="order-info-lists">
          <li>申请退款时间：{{orderDetailInfo.applyRefundTime || '-'}}</li>
          <li>平台处理时间：{{orderDetailInfo.platformDisposeTime || '-'}}</li>
          <li v-if="orderDetailInfo.refundReason">退款原因：{{orderDetailInfo.refundReason}}</li>
        </ul>
      </template>
    </DetailInfoItem>
    <DetailInfoItem title="订单信息">
      <template v-slot:popover>
        <p>平台收益=成本价*服务费率</p>
        <p>渠道价=成本价+成本价*服务费率</p>
      </template>
      <template v-slot:content>
        <ul class="order-info-lists">
          <li>联联订单号：{{orderDetailInfo.llOrderId || '-'}}</li>
          <li>渠道订单号：{{orderDetailInfo.channelOrderId || '-'}}</li>
          <li>订单创建时间：{{orderDetailInfo.creatTime || '-'}}</li>
          <template>
            <li>成本价：¥ {{orderDetailInfo.costPrice || 0}}</li>
            <li>服务费率：{{orderDetailInfo.rate || 0}}%</li>
            <li>平台收益：¥{{orderDetailInfo.refundStatus===2?'-':orderDetailInfo.platformFinance || 0}}</li>
            <li>渠道价：¥{{orderDetailInfo.channelPrice || 0}}</li>
          </template>
        </ul>
      </template>
    </DetailInfoItem>
    <DetailInfoItem title="商品信息" v-if="goods&&goods.length">
      <template v-slot:content>
        <el-table :data="goods" :cell-style="{padding: '4px 0'}" border style="width: 100%">
          <el-table-column label="商品图片" width="150" align="center">
            <template slot-scope="scope">
              <el-image :src="scope.row.faceImg" class="data-item-img"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="productTitle" label="名称" align="center" />
          <el-table-column prop="categoryName" label="分类" align="center" />
          <el-table-column prop="ssskSaleAmount" label="数量" align="center" />
          <el-table-column prop="costPrice" label="成本价" align="center">
            <template slot-scope="scope">
              <span :index="scope.$index">{{orderDetailInfo.costPrice || 0}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="channelPrice" label="渠道价" align="center">
            <template slot-scope="scope">
              <span :index="scope.$index">{{orderDetailInfo.channelPrice || 0}}</span>
            </template>
          </el-table-column>
          <el-table-column label="平台收益" align="center">
            <template slot-scope="scope">
              <span :index="scope.$index">{{orderDetailInfo.refundStatus===2?'-':orderDetailInfo.platformFinance || 0}}</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </DetailInfoItem>
  </div>
</template>

<script> 
import { $sg_getOrderInfo } from '@/api/channel';
import { REFUND_TYPES, WRITE_OFF_TYPES } from '../thirdParty';
import DetailInfoItem from '../components/detailInfoItem.vue';

export default {
  name: 'OrderDetail',
  components: {
    DetailInfoItem,
  },
  props: ['id'],
  data() {
    return {
      formLoading: false,
      // takeoutOrderStatus: [], // 订单状态
      // takeoutRefundState: [], // 退款状态
      orderDetailInfo: {}, // 订单详情
      shopInfo: {},
      goods: [],
    };
  },
  created() {
    this.refundTypes = REFUND_TYPES; // 退款状态
    this.writeOFfTypes = WRITE_OFF_TYPES; // 核销状态
    this.formLoading = true;
    $sg_getOrderInfo({ id: this.id }).then((d) => {
      const { shops, goodsInfoList } = d;
      this.orderDetailInfo = d;
      this.goods = goodsInfoList || [];
      this.shopInfo = shops[0] || {};
    }).finally(() => {
      this.formLoading = false;
    });
  },
  computed: {
    orderStatus() {
      const { refundStatus, status } = this.orderDetailInfo;
      return refundStatus === 2 ? this.refundTypes[refundStatus] : this.writeOFfTypes[status];
    },
  },
  filters: {
    phoneFilter(val) {
      if (!val) return '-';
      const reg = /^(.{3}).*(.{4})$/;
      return val.replace(reg, '$1****$2');
    },
  },
};
</script>

<style scoped lang="scss">
.takeout-order-detail {
  .page-header-custorm {
    background: #fff;
    padding: 10px 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .marketing-type {
    font-size: 18px;
    font-weight: bold;
    .order-status {
      color: rgb(255, 10, 10);
    }
  }
  .order-info-lists {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    color: #666666;
    box-sizing: border-box;
    padding: 10px 20px 0;
    li {
      width: calc(100% / 3);
      box-sizing: border-box;
      padding-bottom: 10px;
      .el-link {
        font-size: 16px;
      }
    }
  }
}
.data-item-img {
  width: 80px;
  height: 40px;
}
</style>
