import { getStringLen, reg2 } from '../thirdParty';

export default {
  channelName({ channelName }) {
    return (channelName ?? '') === '' ? '请输入渠道名称' : '';
  },
  channelType({ channelType }) {
    return !channelType ? '请选择渠道分类' : '';
  },
  channelContact({ channelContact }) {
    if (channelContact) {
      const strLen = getStringLen(channelContact);
      return strLen > 10 ? '请正确输入联系人(5个汉字或10个字符)' : '';
    }
    return '';
  },
  channelPhone({ channelPhone }) {
    if (channelPhone) {
      const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      return !reg.test(channelPhone) ? '请正确输入联系人手机号' : '';
    }
    return '';
  },
  idPerson({ idPerson }) {
    if (idPerson) {
      const strLen = getStringLen(idPerson);
      return strLen > 10 ? '请正确输入法人姓名(5个汉字或10个字符)' : '';
    }
    return '';
  },
  idPersonCard({ idPersonCard }) {
    const idCard = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (idPersonCard && !idCard.test(idPersonCard)) {
      return '请输入正确的法人身份证号';
    }
    return '';
  },
  rate({ rate }) {
    if ((rate ?? '') === '') return '请输入服务费率';
    if (!reg2.test(String(rate))) return '服务费率最多2位小数，最大99.99，最小0';
    return '';
  },
  // 
};
