<template>
  <el-dialog class="k-setRider-dialog" title="审核" :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="visible"
    width="500px">
    <p style="text-align:center;">通过后，用户支付金额{{dataItem.costPrice}}元将原路退还给用户，确认审核通过吗？</p>
    <div class="footer-btns">
      <el-button type="primary" @click="auditDataItem(1)" :disabled="auditing" :loading="auditing">通过</el-button>
      <el-button type="info" @click="innerVisible = true" :disabled="auditing" :loading="auditing">驳回</el-button>
    </div>
    <el-dialog class="k-setRider-dialog" width="500px" title="驳回理由" :close-on-click-modal="false" :close-on-press-escape="false"
      :visible.sync="innerVisible" append-to-body>
      <el-input type="textarea" :rows="2" :autosize="{ minRows: 3}" placeholder="请输入内容" v-model="remark">
      </el-input>
      <div class="innerDialog-footer-btns">
        <el-button type="info" @click="innerVisible = false" :disabled="auditing" :loading="auditing">取消</el-button>
        <el-button type="primary" @click="auditDataItem(2)" :disabled="auditing" :loading="auditing">提交</el-button>
      </div>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
    <span slot="footer" class="dialog-footer"></span>
  </el-dialog>
</template>

<script>
import { $sp_audit } from '@/api/channel';
import { debounce } from '@/utils/index';

export default {
  props: {
    dialogTableVisible: Boolean,
    dataItem: Object,
  },
  model: {
    prop: 'dialogTableVisible',
    event: 'closed',
  },
  data() {
    return {
      innerVisible: false,
      remark: '',
      auditing: false, // 审核中
    };
  },
  computed: {
    visible: {
      get() {
        return this.dialogTableVisible;
      },
      set(val) {
        this.$emit('closed', val);
      },
    },
  },
  methods: {
    // 1 通过 2 驳回,示例值(1)
    // eslint-disable-next-line func-names
    auditDataItem: debounce(function (type) {
      if (type === 2 && (this.remark ?? '') === '') {
        this.$message.error('请输入驳回原因');
        return;
      } else if (type === 1) {
        this.remark = '';
      }
      const params = {
        id: this.dataItem.id,
        remark: this.remark,
        type,
      };
      this.auditing = true;
      $sp_audit({ ...params }).then(() => {
        this.visible = false;
        this.$emit('auditSuccess');
      }).finally(() => {
        this.auditing = false;
      });
    }, 2000, {
      leading: true,
      trailing: false,
    }),
  },
};
</script>

<style scoped lang="scss">
@import "../thirdParty.scss";
.footer-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .el-button {
    margin: 20px 0 0 0;
    width: 200px;
  }
}
.innerDialog-footer-btns {
  text-align: center;
  margin-top: 30px;
  .el-button {
    width: 90px;
  }
}
</style>
