<template>
  <el-dialog class="k-setRider-dialog" :visible.sync="visible" width="555px">
    <template v-slot:title>
      <p><i class="sx-icon-tishi"></i>{{title}}</p>
    </template>
    <div class="dialog-content">
      <p>导出完成时间跟数据量有关，预计在1小时以内，导出过程中的数据变动不会被记
        录</p>
      <p>报表字段：<slot name="tableKey"></slot>
      </p>
      <p>导出成功后默认下载到本电脑，请在电脑<span style="color:#2C68FF;">“下载”</span>文件夹中查看</p>
    </div>

    <div class="footer-btns">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="exportData">确认导出</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { debounce } from '@/utils/index';

export default {
  props: {
    title: String,
    dialogTableVisible: Boolean,
  },
  model: {
    prop: 'dialogTableVisible',
    event: 'closed',
  },
  data() {
    return {
    };
  },
  computed: {
    visible: {
      get() {
        return this.dialogTableVisible;
      },
      set(val) {
        this.$emit('closed', val);
      },
    },
  },
  methods: {
    // eslint-disable-next-line func-names
    exportData: debounce(function () {
      this.$emit('export');
    }, 2000, {
      leading: true,
      trailing: false,
    }),
  },
};
</script>

<style scoped lang="scss">
.k-setRider-dialog ::v-deep {
  .el-dialog__header {
    padding: 14px 12px 10px 12px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  .el-dialog__body {
    box-sizing: border-box;
    padding: 14px 12px 19px 14px;
  }
  .sx-icon-tishi {
    color: #faad14;
    font-size: 20px;
    font-weight: 400;
    margin-right: 10px;
  }
  .dialog-content p {
    position: relative;
    font-size: 14px;
    color: #666666;
    margin-bottom: 15px;
    text-align: justify;
    box-sizing: border-box;
    padding-left: 14px;
    &::before {
      position: absolute;
      left: 0;
      top: 8px;
      display: inline-block;
      content: "";
      width: 4px;
      height: 4px;
      background: #cccccc;
      border-radius: 50%;
    }
  }
  .footer-btns {
    text-align: right;
  }
}
</style>
