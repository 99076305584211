<template>
  <div class="thirdParty-page wh">
    <el-form :inline="true" :model="searchForm" class="search-params" style="width: 100%">
      <el-form-item label="申请退款时间">
        <el-date-picker v-model="times" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期" @change="chooseTime">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="渠道订单号">
        <el-input v-model="searchForm.channelOrderId" maxlength="50" placeholder="输入渠道订单号" clearable></el-input>
      </el-form-item>
      <el-form-item label="联联订单号">
        <el-input v-model="searchForm.lianlianOrderId" maxlength="50" placeholder="输入联联订单号" clearable></el-input>
      </el-form-item>
      <el-form-item label="归属渠道">
        <el-select v-model="searchForm.channelId" filterable remote :remote-method="getChannelListsData" @focus="()=>getChannelListsData()"
          placeholder="请选择" clearable>
          <el-option v-for="item in channelOptionsData" :key="item.id" :label="item.channelName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="searchForm.status" placeholder="请选择" clearable>
          <el-option v-for="(item,index) in auditTypes" :key="index" :label="item" :value="+index">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否失效">
        <el-select v-model="searchForm.isExpire" placeholder="请选择" clearable>
          <el-option v-for="(item,index) in failureTypes" :key="index" :label="item" :value="+index">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="search-button">
        <el-button type="primary" @click="getDataList(1)">搜索</el-button>
        <el-button type="info" @click="resetGetData">重置</el-button>
      </el-form-item>
      <span class="oder-number-statics">订单总笔数:{{statisticalData.orderCount.value}}</span>
    </el-form>
    <div class="thirdParty-page-content-table">
      <el-table :data="tableData" :cell-style="cellStyle" :height="tableHeight" v-loading="tableLoading" element-loading-text="数据加载中..."
        class="thirdParty-table-css" style="width: 100%;" border>
        <el-table-column prop="channelName" label="归属渠道" width="180" />
        <el-table-column prop="channelOrderId" label="渠道订单号" width="150" />
        <el-table-column prop="lianlianOrderId" label="联联订单号" width="150" />
        <el-table-column prop="productName" label="商品名称" width="200" />
        <el-table-column label="店铺名称" width="180">
          <template slot-scope="scope">
            {{getShopInfo(scope.row.productShopInfo, 'name')}}
          </template>
        </el-table-column>
        <el-table-column label="商户电话" width="115">
          <template slot-scope="scope">
            {{getShopInfo(scope.row.productShopInfo, 'phoneNumber')}}
          </template>
        </el-table-column>
        <el-table-column prop="customerPhone" label="客户电话" width="100">
          <template slot-scope="scope">
            {{phoneFilter(scope.row.customerPhone)}}
          </template>
        </el-table-column>
        <el-table-column prop="orderCreateTime" label="订单创建时间" width="160" />
        <el-table-column label="状态" width="80">
          <template slot-scope="scope">
            {{auditTypes[scope.row.status] || ''}}
          </template>
        </el-table-column>
        <el-table-column label="是否失效" width="80">
          <template slot-scope="scope">
            {{getResult(scope.row.expireTime)}}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="申请退款时间" width="160" />
        <el-table-column prop="costPrice" label="订单实付金额" width="120" />
        <el-table-column prop="remark" label="驳回理由" />
        <el-table-column fixed="right" label="操作" width="80">
          <template slot-scope="scope">
            <el-button type="text" @click="auditData(scope.row)" v-if="scope.row.status===1">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="thirdParty-el-pagination" background :page-count="totalPage" :current-page.sync="searchForm.pageCurrent"
      @current-change="getDataList">
    </el-pagination>
    <AuditDialog v-model="auditDialogVisible" v-if="auditDialogVisible" :dataItem="checkPending" @auditSuccess="getDataList" />
  </div>
</template>

<script>
import { $sg_queryOrderList } from '@/api/channel';
import thirdMixins from '../thirdMixins';
import AuditDialog from '../components/auditDialog.vue';
import { FAILURE_TYPES, AUDIT_TYPES } from '../thirdParty';

export default {
  name: 'CheckPendingLists',
  mixins: [thirdMixins],
  components: {
    AuditDialog,
  },
  data() {
    return {
      auditDialogVisible: false,
      statisticalData: {
        orderCount: {
          index: 0,
          label: '总订单量/笔',
          value: 0,
        },
      },
      times: [],
      searchForm: {
        channelId: '', // 渠道商id,示例值(1)
        channelOrderId: '', // 渠道商订单号
        isExpire: '', // 是否失效 1 是 2 否 
        lianlianOrderId: '', // 联联订单号
        refundEndTime: '', // 申请退款时间（yyyy-MM-dd）,
        refundStartTime: '', // 申请退款时间（yyyy-MM-dd）,示例值(2022-03-01)
        status: '', // 1 待审核 2 审核成功 3 驳回,示例值(1)
        pageCurrent: 1, //
        pageSize: 20, //
      },
      checkPending: null,
      channelOptionsData: [], // 归属渠道
    };
  },
  created() {
    this.failureTypes = FAILURE_TYPES;
    this.auditTypes = AUDIT_TYPES;
    this.getDataList();
    // this.getChannelListsData();
  },
  methods: {
    // 时间选择
    chooseTime(val) {
      this.searchForm.refundStartTime = val?.[0] || '';
      this.searchForm.refundEndTime = val?.[1] || '';
    },
    getShopInfo(shopInfo, type) {
      return shopInfo ? JSON.parse(shopInfo).map((item) => {
        if (type === 'phoneNumber') {
          return this.phoneFilter(item[type]);
        }
        return item[type];
      }).join(',') : '-';
    },
    getResult(expireTime) {
      return new Date(expireTime).getTime() < new Date().getTime() ? '是' : '否';
    },
    getDataList(page = null) {
      if (page) {
        this.searchForm.pageCurrent = page;
      }
      this.tableLoading = true;
      $sg_queryOrderList({ ...this.searchForm }).then((d) => {
        const { refundOrderPage, refundOrderStaticsVO } = d;
        this.tableData = refundOrderPage?.records || [];
        this.totalPage = refundOrderPage?.pages || 1;
        if (refundOrderStaticsVO) {
          const arr = Object.keys(this.statisticalData);
          arr.forEach((item) => {
            this.statisticalData[item].value = refundOrderStaticsVO[item] || 0;
          });
        }
      }).finally(() => {
        this.tableLoading = false;
      });
    },
    // 开启审核弹框
    auditData(row) {
      this.checkPending = row;
      this.auditDialogVisible = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../thirdParty.scss";
.search-params {
  position: relative;
  .oder-number-statics {
    display: inline-block;
    padding: 0 10px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>

