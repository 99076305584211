<template>
  <div class="order-header">
    <ul class="order-header-tabs" v-if="tabs.length" @click="checkTab">
      <li v-for="(item, index) in tabs" :key="index" :id="index" :class="activedTab == index?'actived':''">{{item}}</li>
    </ul>
    <div class="statistical-content">
      <ul class="order-header-statistical">
        <li class="statistical-item" :style="{background:colorLists[item.index]}" v-for="(item,index) in statisticalData" :key="index">
          <p class="item-title">{{item.label}}</p>
          <p class="total-number">{{item.value || 0}}</p>
        </li>
      </ul>
      <el-popover v-if="prompt" placement="top-start" title="" trigger="hover">
        <p v-html="prompt"></p>
        <i class="sx-icon-yiwen" slot="reference"></i>
      </el-popover>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => ([]),
    },
    prompt: String,
    statisticalData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      activedTab: 0,
      colorLists: {
        0: 'rgba(15, 120, 217, 0.5)',
        1: 'rgba(223, 110, 11, 0.5)',
        2: 'rgba(223, 11, 11, 0.5)',
        3: 'rgba(191, 188, 5, 0.5)',
      },
    };
  },
  methods: {
    checkTab(e) {
      this.activedTab = e.target.id;
      this.$emit('tabCheckChange', this.activedTab);
    },
  },
};
</script>

<style scoped lang="scss">
.order-header {
  padding-bottom: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #f5f5f5;
  .order-header-tabs {
    display: flex;
    margin-bottom: 10px;
    font-size: 16px;
    li {
      position: relative;
      margin-right: 16px;
      color: #444444;
      cursor: pointer;
    }
    .actived {
      color: #444;
      font-weight: bold;
      &::after {
        position: absolute;
        bottom: -2px;
        left: 0;
        display: inline-block;
        content: "";
        height: 4px;
        width: 100%;
        border-radius: 2px;
        background: rgba(255, 10, 10, 0.5);
      }
    }
  }
  .statistical-content {
    display: flex;
    .order-header-statistical {
      display: flex;
      .statistical-item {
        width: 150px;
        height: 68px;
        padding: 0 12px;
        color: #fff;
        font-size: 14px;
        margin-right: 12px;
        border-radius: 5px;
        .item-title {
          margin: 8px 0 4px 0;
          opacity: 0.9;
        }
        .total-number {
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
    ::v-deep .el-popover__reference-wrapper {
      display: inline-block;
      margin-top: 12px;
    }
    .sx-icon-yiwen {
      margin-top: 12px;
      color: #999999;
      font-size: 17px;
    }
  }
}
</style>
