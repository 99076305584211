<template>
  <el-dialog class="k-setRider-dialog" :title="`${!!form.id?'编辑':'新增'}渠道商`" top="8vh" :close-on-click-modal="false"
    :close-on-press-escape="false" :visible.sync="visible" width="740px" v-loading="dialogLoading" element-loading-text="数据加载中...">
    <el-form :inline="true" :model="form" class="channerl-creat-form">
      <el-form-item label="渠道名称" required>
        <el-input v-model="form.channelName" placeholder="输入渠道商名称" clearable></el-input>
      </el-form-item>
      <el-form-item label="渠道ID" v-if="!!form.id">
        <el-input v-model="form.id" placeholder="渠道商ID" :disabled="!!form.id" clearable></el-input>
      </el-form-item>
      <el-form-item label="渠道分类">
        <el-select v-model="form.channelType" placeholder="请选择" clearable>
          <el-option v-for="(item,index) in channelTypes" :key="index" :label="item" :value="+index">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所在城市">
        <el-cascader v-model='form.channelCity' ref="city" :options="areaOptions" :props="{value:'label'}" collapse-tags clearable>
        </el-cascader>
      </el-form-item>
      <div class="contacts">
        <div class="contacts-info">
          <el-form-item class="w-upload" label="联系人">
            <el-input v-model="form.channelContact" placeholder="请输入联系人姓名" clearable></el-input>
          </el-form-item>
          <el-form-item class="w-upload" label="联系人手机号">
            <el-input v-model="form.channelPhone" type="number" onInput="if(value.length>11)value=value.slice(0,11)" placeholder="请输入联系人手机号"
              clearable>
            </el-input>
          </el-form-item>
        </div>
        <el-form-item label="渠道图标">
          <UploadImg :options="channerlOptions" v-model="form.channelIcon" />
        </el-form-item>
      </div>
      <el-divider></el-divider>
      <template v-if="form.channelType == 1">
        <el-form-item label="法人姓名">
          <el-input v-model="form.idPerson" placeholder="输入法人姓名" clearable></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号">
          <el-input v-model="form.idPersonCard" :maxlength="18" placeholder="输入法人身份证号码" clearable></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码">
          <el-input v-model="form.creditCode" placeholder="输入统一社会信用代码" clearable></el-input>
        </el-form-item>
        <el-form-item label="渠道商企业名称">
          <el-input v-model="form.channelEnterpriseName" placeholder="输入渠道商企业名称" clearable></el-input>
        </el-form-item>
        <el-form-item class="qualification" label="资质信息">
          <div class="qualification-information">
            <UploadImg :options="qualificationOptions" v-model="form.idPositivePhoto" label="法人身份证正面照" />
            <UploadImg :options="qualificationOptions" v-model="form.idBackPhoto" label="法人身份证背面照" />
            <UploadImg :options="qualificationOptions" v-model="form.businessLicense" label="营业执照" />
          </div>
        </el-form-item>
        <el-divider></el-divider>
      </template>
      <el-form-item class="rote" label="平台服务费率" required>
        <el-input v-model="form.rate" placeholder="平台服务费率" type="number" clearable>
          <template v-slot:suffix>%</template>
        </el-input>
        <p class="description">平台收益=成本价*平台服务费率</p>
        <p class="description">渠道价=成本价+成本价*平台服务费率</p>
      </el-form-item>
      <el-divider content-position="left">开发设置</el-divider>
      <el-form-item label="下单回调地址">
        <el-input v-model="form.orderCreateNotifyUrl" placeholder="输入下单回调地址" clearable></el-input>
      </el-form-item>
      <el-form-item label="核销回调地址">
        <el-input v-model="form.cancelAfterVeriNotifyUrl" placeholder="输入核销回调地址" clearable></el-input>
      </el-form-item>
      <el-form-item label="退款地址回调">
        <el-input v-model="form.refundNotifyUrl" placeholder="输入退款地址回调" clearable></el-input>
      </el-form-item>
      <el-form-item label="密钥" v-if="!!form.id">
        <el-input v-model="form.name" placeholder="密钥" :disabled="!!form.id" clearable></el-input>
      </el-form-item>
    </el-form>
    <div class="footer-btns">
      <el-button type="info" :loading="saveing" :disabled="saveing" @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="saveing" :disabled="saveing" @click="submitInfo">确认{{!!form.id?'编辑':'新增'}}
      </el-button>
    </div>
    <span slot="footer" class="dialog-footer"></span>
  </el-dialog>
</template>

<script>
import { $sp_addChannel, $sp_updateChannel, $sg_selectByChannelId } from '@/api/channel';
import { $sp_areaTree } from '@/api/agentAll';
import UploadImg from './k-uploadImg.vue';
import { CHANNEL_TYPES } from '../thirdParty';
import addOreditChannerlVerifyRules from './addOreditChannerlVerifyRules';

export default {
  components: {
    UploadImg,
  },
  props: {
    dialogTableVisible: Boolean,
    channelId: [Number, String],
  },
  model: {
    prop: 'dialogTableVisible',
    event: 'closed',
  },
  data() {
    return {
      dialogLoading: false,
      saveing: false, // 是否提交
      form: {
        businessLicense: '', // 营业执照
        cancelAfterVeriNotifyUrl: '', // 订单核销回调
        channelCity: '', // 所在城市
        channelCityCode: '', // 所在城市code
        channelContact: '', // 联系人
        channelEnterpriseName: '', // 渠道商企业名称
        channelIcon: '', // 渠道图标
        channelName: '', // 渠道商名称
        channelPhone: '', // 联系人手机号
        channelType: 2, // 渠道商类型 1 企业 2 个人
        creditCode: '', // 信用代码
        id: '', // 主键,自增
        idBackPhoto: '', // 身份证背面照
        idPerson: '', // 法人姓名
        idPersonCard: '', // 法人身份证
        idPositivePhoto: '', // 身份证正面照
        orderCreateNotifyUrl: '', // 订单下单回调
        rate: '', // 服务费率
        refundNotifyUrl: '', // 退款回调地址
      },
      areaOptions: [],
    };
  },
  computed: {
    visible: {
      get() {
        return this.dialogTableVisible;
      },
      set(val) {
        this.$emit('closed', val);
      },
    },
  },
  created() {
    this.channelTypes = CHANNEL_TYPES;
    this.channerlOptions = {
      width: '215px',
      height: '86px',
    };
    this.qualificationOptions = {
      width: '140px',
      height: '86px',
    };
    this.getChannelDetail();
  },
  mounted() {
    this.areaTree();
  },
  methods: {
    // 新增编辑数据
    submitInfo() {
      const infoLists = Object.keys(addOreditChannerlVerifyRules);
      let errInfo = '';
      const { channelCity, channelType } = this.form;
      const methods = [$sp_addChannel, $sp_updateChannel];
      const methodsType = this.form.id ? 1 : 0;
      const choosedCitys = this.$refs.city.getCheckedNodes()[0];
      for (let i = 0; i < infoLists.length; i += 1) {
        const element = infoLists[i];
        errInfo = addOreditChannerlVerifyRules[element](this.form);
        if (errInfo) break;
      }
      if (errInfo) {
        this.$message.error(errInfo);
        return;
      }
      this.saveing = true;
      if (channelCity?.length && typeof channelCity !== 'string') {
        this.form.channelCity = channelCity.join(',');
      }
      if (choosedCitys?.pathNodes?.length) {
        this.form.channelCityCode = choosedCitys.pathNodes.map((item) => item.data.value).join(',');
      }
      if (channelType === 2) { // 个人清空法人信息
        const arr = [
          'idPerson',
          'idPersonCard',
          'creditCode',
          'channelEnterpriseName',
          'idPositivePhoto',
          'idBackPhoto',
          'businessLicense',
        ];
        arr.forEach((item) => {
          this.form[item] = '';
        });
      }
      methods[methodsType]({ ...this.form }).then(() => {
        this.visible = false;
        this.$emit('submitSuccess');
      }).finally(() => {
        this.saveing = false;
      });
    },
    // 获取渠道详情
    getChannelDetail() {
      if (!this.channelId) return;
      this.dialogLoading = true;
      $sg_selectByChannelId({ channelId: this.channelId }).then((d) => {
        this.form = {
          ...d,
          channelCity: d?.channelCity?.split(',') || '',
        };
      }).finally(() => {
        this.dialogLoading = false;
      });
    },
    // 获取区域树状图
    areaTree() {
      $sp_areaTree().then((res) => {
        this.areaOptions = res.children;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../thirdParty.scss";
.k-setRider-dialog ::v-deep {
  .el-select,
  .el-cascader {
    width: 217px;
  }
}
.el-form-item:not(.w-upload, .qualification, .rote) {
  width: 50%;
  margin: 0 0 10px 0;
  text-align: right;
}
.w-upload {
  width: 100%;
  margin: 0 0 10px 0;
  text-align: right;
}
.el-divider--horizontal {
  margin: 0 0 10px 0;
}
.contacts {
  display: flex;
  .contacts-info {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
}
.qualification {
  margin-bottom: 10px;
  ::v-deep .el-form-item__label {
    width: 132px;
  }
  .qualification-information {
    display: flex;
    .k-upload-outBox {
      margin-right: 20px;
    }
  }
}
.rote {
  .el-input {
    width: 217px;
  }
  ::v-deep .el-form-item__label {
    width: 132px;
  }
  .description {
    text-align: left;
    color: rgb(255, 10, 10);
    font-size: 12px;
    height: 24px;
    line-height: 24px;
  }
}

.footer-btns {
  text-align: center;
  .el-button{
    width:90px;
  }
}
</style>
