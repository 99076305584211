<template>
  <div class="k-upload-outBox">
    <div class="k-upload-img" :style="{width:options.width,height:options.height}">
      <el-image :src="uploadImg" fit="cover" v-if="uploadImg"></el-image>
      <i v-else class="el-icon-circle-plus-outline"></i>
      <div class="handle-lists">
        <p class="handle-btns">
          <i class="el-icon-upload2" @click="imgUpload"></i>
          <i class="el-icon-delete" @click="uploadImg = ''"></i>
        </p>
      </div>
    </div>
    <p class="upload-label" v-if="label">{{label}}</p>
  </div>
</template>

<script>
import { FileUploader, ChooseFile } from '@base/utils';

export default {
  props: {
    options: {
      type: Object,
      default: () => ({
        width: '100px',
        height: '100px',
        upLoadW: '',
        upLoadH: '',
      }),
    },
    label: String,
    imgUrl: String,
  },
  model: {
    prop: 'imgUrl',
    event: 'uploadchange',
  },
  computed: {
    uploadImg: {
      get() {
        return this.imgUrl;
      },
      set(val) {
        this.$emit('uploadchange', val);
      },
    },
  },
  methods: {
    // 上传商品图片
    imgUpload() {
      const change = new ChooseFile();
      change.chooseImage(this.getFile.bind(this));
    },
    getFile(file) {
      const { upLoadW, upLoadH } = this.options;
      const fileUploder = new FileUploader(file, { width: upLoadW, height: upLoadH, addLibrary: false });
      fileUploder.upload().then((url) => {
        this.uploadImg = url;
      }).catch((error) => {
        this.$message.error(error.message || '上传失败，请重新上传');
      });
    },
  },
};
</script>

<style scoped lang="scss">
.k-upload-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid #e4e4e4;
  color: #666666;
  border-radius: 4px;
  .el-icon-circle-plus-outline {
    display: block;
    font-size: 28px;
    color: #c5c5c5;
  }
  &:hover {
    .handle-lists {
      display: block;
    }
    .el-icon-circle-plus-outline {
      display: none;
    }
  }
  .el-image {
    width: 100%;
    height: 100%;
  }
  .handle-lists {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    .handle-btns {
      position: absolute;
      top: calc(50% - 10px);
      width: 100%;
      text-align: center;
      .el-icon-upload2,
      .el-icon-delete {
        color: #fff;
        font-size: 26px;
        cursor: pointer;
        margin: 0 10px;
      }
    }
  }
}
.upload-label {
  text-align: center;
  color: #a1a1a1;
  height: 28px;
  line-height: 28px;
}
</style>
