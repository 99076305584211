<template>
  <el-card class="detail-info-item">
    <div slot="header" style="display:flex;justify-content: space-between;">
      <span class="header-title">{{title}}</span>
      <el-popover placement="top-start" popper-class="k-public-el-popover" title="" trigger="hover" v-if="$slots.popover">
        <p>
          <slot name="popover"></slot>
        </p>
        <i class="sx-icon-yiwen" slot="reference"></i>
      </el-popover>
    </div>
    <div class="info-item-content">
      <slot name="content"></slot>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    title: String,
  },
  data() {
    return {

    };
  },
};
</script>

<style scoped lang="scss">
.detail-info-item {
  margin-top: 14px;
  ::v-deep .el-card__header {
    padding: 10px 20px;
  }
  .header-title{
    font-size: 16px;
    font-weight: bold;
  }
  ::v-deep .el-card__body {
    padding: 0 20px;
  }
  .info-item-content {
    background: #ffffff;
    border-radius: 6px;
  }
  .sx-icon-yiwen {
    color: #999999;
    font-size: 17px;
    margin-left: 8px;
    font-weight: normal;
  }
}
</style>
